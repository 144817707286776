/* html, body {
  height: 100%;
  width: 100%;
} */


*, ::after, ::before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: fieldtext !important;
}

form input:-webkit-autofill, form textarea:-webkit-autofill, form input:-webkit-autofill:hover, form textarea:-webkit-autofill:hover, form input:-webkit-autofill:focus, form textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: #666!important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.primary-gradient {
  background: linear-gradient(to bottom, rgba(57,179,74,1) 0%, rgba(98,188,68,1) 51%, rgba(99,188,68,1) 52%, rgba(138,196,63,1) 100%);
}

.secondary-button {
  background-color: #285C69;
}

.Mui-error label.MuiFormLabel-root {
  color: #d32f2f;
}

.Mui-error  .MuiInputBase-root.MuiInput-underline:before {
  border-bottom: 2px solid #d32f2f;
}

.disabled-select .MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.38);
}

.disabled-select svg.MuiSelect-icon {
  color: rgba(0, 0, 0, 0.18);
}

.disabled-select .MuiInput-underline {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
  pointer-events: none;
}

.disabled-select .MuiInput-underline:before {
  border-bottom-style: dotted;
}
/* .MuiInputBase-root:before {
  border-bottom: 2px solid #CACACA!important;
} */



@media (min-width:600px) {

}
